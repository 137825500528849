(function () {
  'use strict';

  class TimelineCtrl {
    constructor(Groups, Discussions, $window, $rootScope, Classrooms) {
      let vm = this;
      vm.Groups = Groups;
      vm.Discussions = Discussions;
      vm.Classrooms = Classrooms;
      vm.$rootScope = $rootScope;
      vm.barChartOptions = {
        chart: {
          type: 'discreteBarChart',
          height: 450,
          x: function (d) {
            return d.label;
          },
          y: function (d) {
            return d.value;
          },
          showValues: true,
          valueFormat: function (d) {
            return $window.d3.format(',d')(d);
          },
          duration: 500,
          xAxis: {
            axisLabel: 'Answer'
          },
          yAxis: {
            axisLabel: 'Count',
            axisLabelDistance: -10
          }
        }
      };
      vm.init();
    }
    init() {
      let vm = this;
      delete vm.groupsIndex;
      if (vm.$rootScope.user.classroom_ids) {
        vm.Classrooms.classroomView({id: vm.$rootScope.user.classroom_ids[0]})
          .then((data) => {
            vm.groupsIndex = {
              documents: data.groups
            };
          });
      }
    }
    activeDiscussionView(group) {
      let vm = this,
          obj = {
            group: 'group',
            groupid: group.id
          };
      vm.Discussions.activeDiscussionView(obj)
        .then((data)=> {
          group.discussions = data.documents;
        });
    }
    pollMetrics(group) {
      let vm = this,
          obj = {
            id: group.id
          };
      vm.Groups.pollMetrics(obj)
        .then((data)=> {
          group.polls = data.documents;
        });
    }
    setGraph(group, data) {
      let graph = [
          {
            key: 'Results',
            values: []
          }
      ];
      angular.forEach(data, function (v) {
        graph[0].values.push({label: v.option_label, value: v.count});
      });
      group.graph = graph;
    }
  }

  /**
   * @ngdoc object
   * @name dashboard.timeline.controller:TimelineCtrl
   *
   * @description
   *
   */
  angular
    .module('dashboard.timeline')
    .controller('TimelineCtrl', TimelineCtrl);
}());
